// src/CircularProgress.js
import React, { useEffect, useState, useRef } from "react";
import VanillaTilt from "vanilla-tilt";
import "./main.css"; // Bu dosyayı stiller için oluşturacağız

const CircularProgress = ({
  className,
  progressClass,
  progressValueClass,
  endValue,
  color,
  delay,
  text,
}) => {
  const [progressValue, setProgressValue] = useState(0);
  const tiltRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 700) {
        setTimeout(() => {
          const interval = setInterval(() => {
            setProgressValue((prev) => {
              if (prev < endValue) {
                return prev + 1;
              } else {
                clearInterval(interval);
                return prev;
              }
            });
          }, 30);
        }, delay);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [endValue, delay]);

  useEffect(() => {
    if (tiltRef.current) {
      VanillaTilt.init(tiltRef.current, {
        max: 25,
        speed: 700,
        glare: true,
        "max-glare": 0.5,
      });
    }
    return () => {
      if (tiltRef.current) {
        tiltRef.current.vanillaTilt.destroy();
      }
    };
  }, []);

  return (
    <div ref={tiltRef} className={`${className} tilt`}>
      <div
        className={`${progressClass} tilt_inner_img`}
        style={{
          background: `conic-gradient(${color} ${
            progressValue * 3.6
          }deg, transparent 0deg)`,
        }}
      >
        {/* <span className={progressValueClass}>{progressValue}%</span> */}
        <span className="text tilt-inner">{text}</span>
      </div>
    </div>
  );
};

export default CircularProgress;
