// src/AirtableList.js
import React, { useEffect, useState } from "react";
import Airtable from "airtable";

const BASE_ID = "appNZdOh0AfVNt4ki";
const PERSONAL_ACCESS_TOKEN =
  "patDeEknYNBJqnnG1.82d11a5dfea82233948cc9b16b83f29b83184f1c021bac96b34760aefa80fbcf";

const AirtableList = () => {
  const [records, setRecords] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecords = async () => {
      const base = new Airtable({ apiKey: PERSONAL_ACCESS_TOKEN }).base(
        BASE_ID
      );

      try {
        const recordsArray = [];
        await base("Tools")
          .select({
            view: "tools", // Görünüm adını buraya ekle
          })
          .eachPage((records, fetchNextPage) => {
            records.forEach((record) => {
              recordsArray.push(record);
            });
            fetchNextPage();
          });
        setRecords(recordsArray);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching records from Airtable:", error);
      }
    };

    fetchRecords();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div id="tools" className="tools tilt">
      <div className="chapter-1 tilt">
        {records.map((record) => (
          <div key={record.id} className="tilt_js">
            <p className="tools_p ">
              {record.fields.Name}
              <img
                src={record.fields.Logo_url}
                alt={record.fields.Name}
                className="tilt_inner_img"
              />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AirtableList;
